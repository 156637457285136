@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Play:wght@400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --clr-white: #ffffff;
  --clr-black: #202020;
  --clr-blue: #6c63ff;
  --clr-blue-2: #4c46b6;
}

body {
  margin: 0;
  font-family: "Play";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ------------------------------------------------------ */
/* -- NAVIGATION -- */

nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  background-color: #202020;
}

.nav__brand {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;
}

/* .nav__brand h1 {
  color: var(--clr-blue);
  font-size: clamp(1.75em, 4vw, 2.5em);
  font-weight: 800;
} */

.logo {
  max-width: 100%;
  height: auto;
}

.nav__connect {
  width: 175px;
  height: 50px;
  margin: 0 auto;

  background-color: var(--clr-blue);
  color: var(--clr-white);

  border: none;
  border-radius: 4px;

  font-family: "Play";
  font-size: 1.1em;
  font-weight: 600;

  cursor: pointer;
  transition: all 250ms ease;
}

.nav__connect:hover {
  background-color: var(--clr-blue-2);
}

/* ------------------------------------------------------ */
/* -- FORM -- */

.form {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 600px;
}

form {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  margin: 0 25px;
}

form input[type="text"] {
  width: 250px;
  height: 50px;
  margin: 10px 0;
  padding: 10px;

  border: 1px solid var(--clr-blue);
  border-radius: 4px;

  font-size: 1.1em;
}

form input[type="button"] {
  background-color: var(--clr-blue);
  color: var(--clr-white);

  width: 250px;
  height: 50px;
  margin: 10px 0;
  padding: 10px;

  border: none;
  border-radius: 4px;

  font-family: "Play";
  font-size: 1.1em;
  font-weight: 400;

  cursor: pointer;
  transition: all 250ms ease;
}

form input[type="button"]:hover {
  background-color: var(--clr-blue-2);
}

/* ------------------------------------------------------ */
/* IMAGE */
.image {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  width: 512px;
  height: 512px;
  margin: 0 25px;
  border: 3px solid var(--clr-blue);
  border-radius: 4px;
  overflow: hidden;
  transition: all 0.5s ease;
}

.image img {
  width: 512px;
  height: 512px;
  border-radius: 6px;
  transition: all 0.5s ease;
}

.image:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 20px 0px var(--clr-blue-2);
}

.image__placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image__placeholder p {
  margin: 0 10px;
}

/* Model Selection style */

.model-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9261ca; /* violet */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  padding: 10px 24px;
}

.model-header {
  text-align: center;
  font-size: 2em;
  color: #ffffff;
  padding: 30px 0;
  /* border-top: 3px solid var(--clr-blue); */
  background-color: #202020; /* black */
  margin-top: 50px;
  font-weight: bold;
}

.model-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px;
  padding: 20px;
  border: 0.5px solid var(--clr-blue);
  border-radius: 4px;
  background-color: #f5f5f5;
}
/*
.model-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--clr-blue);
  width: 100%;
  padding: 25px 0;
  border-top: 1px solid var(--clr-blue);
  border-bottom: 1px solid var(--clr-blue);
  background-color: #f5f5f5;  
  margin: 15px 0;  
}
*/

.models {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  align-items: center;
}

/* ------------------------------------------------------ */
/* -- TEXT -- */
p {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .form {
    flex-direction: column;
  }
}
